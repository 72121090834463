/* eslint-disable */
import React from 'react'
import Helmet from '../../components/Helmet'

import { graphql } from 'gatsby'

import marriedCoupleIceCream from '../../images/heroes/elderly-couple-eating-ice-cream-and-smiling.jpg'
import marriedCoupleIceCreamMobile from '../../images/heroes/elderly-couple-eating-ice-cream-and-smiling-mobile.jpg'

import { StaticImage } from 'gatsby-plugin-image'

import aetna from '../../images/brand-logos/aetna.svg'
import cigna from '../../images/brand-logos/cigna.svg'
import regence from '../../images/brand-logos/regence.svg'
import united from '../../images/brand-logos/united-healthcare.svg'
import wellcare from '../../images/brand-logos/wellcare.svg'
import mutual from '../../images/brand-logos/mutual-omaha.svg'

import ResponsiveGrid from '../../components/GridLayouts/ResponsiveGrid'

import {
  Accordion,
  AvailabilityForm,
  Billboard,
  Column,
  Columns,
  LinkButton,
  List,
  ListItem,
  Stack,
  Typography,
  VariableContent,
  useMapi,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import header from '../../components/Header'
import footer from '../../components/Footer'
import headerData from '../../data/headerData.js'
import isCallCenterOpen from '../../../../../utils/isOpen'

const Home = ({ data }) => {
  const brandyList = ['disclaimer-hcp-medicareadvantagelp']
  const disclaimerList = brandyList
    .map((disclaimerId) =>
      data.allBrandyDisclaimer.edges.find(
        ({ node }) => node.brandy_id === disclaimerId
      )
    )
    .filter((node) => node)

  const footerDisclaimers = disclaimerList.map(({ node }) => {
    return {
      brandy: {
        text: node.text,
        symbol: node.symbol,
        key: node.brandy_id,
      },
    }
  })

  const brandLogos = [
    { src: aetna, alt: 'Aetna logo' },
    { src: cigna, alt: 'Cigna logo' },
    { src: regence, alt: 'Regence logo' },
    { src: united, alt: 'United Healthcare logo' },
    { src: wellcare, alt: 'Wellcare logo' },
    { src: mutual, alt: 'Mutual of Omaha logo' },
  ]

  const { rotatedNumber, requestId } = useMapi()

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(brandyList, data.allBrandyDisclaimer.edges),
        seo: {
          title:
            'Medicare Advantage (Part C) Plans | Healthcareplans.com | 833-316-3752',
          description:
            'Medicare Advantage plans include coverage benefits additional to Medicare Parts A and B. Consult with our experts to choose the right Medicare Advantage plan.',
          canonical: 'https://healthcareplans.com/plans/medicare-advantage/ ',
          robots: 'follow,index',
        },
        path: '/plans/medicare-advantage',
        promoCode: '169414',
        ringPool: 'MED',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://healthcareplans.com/',
          siteName: 'healthcareplans',
          alternateName: 'healthcareplans',
          removeTrailingSlashes: true,
        },
      },
    },
    defaultRobots: 'follow,index',
    main: (
      <>
        <Helmet />
        <div className="hero-content">
          <div className="wrapper">
            <Billboard
              variant="full"
              textColor="Dark"
              image={
                <img
                  src={marriedCoupleIceCream}
                  alt="elderly married couple walking on beach with bicycles and smiling"
                  className="married-couple-beach-hero"
                />
              }
              mobileImage={
                <img
                  src={marriedCoupleIceCreamMobile}
                  alt="elderly married couple walking on beach with bicycles and smiling"
                />
              }
              mainContent={
                <>
                  <Typography variant="h1">
                    Compare Medicare Advantage plans online!
                  </Typography>

                  <Typography variant="h4">
                    If you’re turning 65 or have a qualifying life event, enter
                    your zip code to view plans in your area. Personalize your
                    results by adding your preferred doctors and essential
                    prescriptions.
                  </Typography>

                  {isCallCenterOpen() ? (
                    <LinkButton
                      variant="feature"
                      to={`tel:${rotatedNumber}`}
                      color="primary"
                      className=""
                      requestId={requestId}
                    >
                      Call Now {rotatedNumber}
                    </LinkButton>
                  ) : (
                    <AvailabilityForm
                      placeholderText="Zip Code"
                      buttonText="View Pricing"
                      buttonColor="primary"
                      butttonBackgroundColor="black"
                      className="avail-form"
                    />
                  )}
                </>
              }
            />
          </div>
        </div>

        <VariableContent
          alignMainContent="center"
          backgroundColor="light"
          mainContent={
            <>
              <Typography variant="h2">
                Types of Medicare Advantage plans
              </Typography>
            </>
          }
          style={{
            color: '#1b0b54',
          }}
        >
          <Stack>
            <Columns leftAligned>
              <Column>
                <StaticImage
                  src="../../images/icons/hmo-plans.svg"
                  alt="HMO plans icon"
                  layout="fixed"
                  width={64}
                  height={64}
                  className="product-icons"
                />
                <Typography variant="h5">
                  Health Maintenance Organization (HMO) plans
                </Typography>
                <Typography variant="body">
                  Medicare Advantage Health Maintenance Organization (HMO) plans
                  provide networks of doctors, hospitals, and other healthcare
                  professionals that policyholders are required to use.
                </Typography>
                <Typography variant="body">
                  Exceptions to this rule include emergency care, out-of-area
                  urgent care, and temporary out-of-area dialysis.
                </Typography>
                <LinkButton
                  variant="feature"
                  to="/plans/medicare-advantage/hmo"
                  color="primary"
                  requestId={requestId}
                  outlined
                >
                  More About HMO Plans
                </LinkButton>
              </Column>
              <Column>
                <StaticImage
                  src="../../images/icons/ppo-plans.svg"
                  alt="PPO plans icon"
                  layout="fixed"
                  width={64}
                  height={64}
                  className="product-icons"
                />
                <Typography variant="h5">
                  Preferred Provider Organization (PPO) plans
                </Typography>
                <Typography variant="body">
                  Medicare Advantage Preferred Provider Organization (PPO) plans
                  provide networks of doctors, hospitals, and other healthcare
                  professionals and facilities. Policyholders are not required
                  to use in-network providers, but while out-of-network
                  providers are covered, they generally come at a higher cost.
                </Typography>
                <LinkButton
                  variant="feature"
                  to="/plans/medicare-advantage/ppo"
                  color="primary"
                  requestId={requestId}
                  outlined
                >
                  More About PPO Plans
                </LinkButton>
              </Column>
              <Column>
                <StaticImage
                  src="../../images/icons/snp-plans.svg"
                  alt="SNP plans icon"
                  layout="fixed"
                  width={64}
                  height={64}
                  className="product-icons"
                />
                <Typography variant="h5">Special Needs (SNPs) plans</Typography>
                <Typography variant="body">
                  Medicare Advantage Special Needs Plans (SNPs) cover the same
                  Medicare Parts A & B benefits that all Medicare Advantage
                  plans cover. They may also cover extra services for the
                  special groups they serve, such as care coordination services,
                  tailored benefits and provider choices, and specific
                  prescription drugs. SNPs may be structured either as an HMO
                  plan or a PPO plan, and are only available to those with
                  specific conditions, certain healthcare needs, or combined
                  Medicare and Medicaid eligibility.
                </Typography>
                <LinkButton
                  variant="feature"
                  to="/plans/snp"
                  color="primary"
                  requestId={requestId}
                  outlined
                >
                  More About SNP Plans
                </LinkButton>
              </Column>
            </Columns>
          </Stack>
        </VariableContent>

        <VariableContent
          alignMainContent="Center"
          alignImageToBottom={false}
          centeredContent={false}
          backgroundColor="white"
          customLink={null}
          className="brand-logos header"
          mainContent={
            <Typography variant="h2">
              We partner with these leading insurance brands{' '}
            </Typography>
          }
        >
          <ResponsiveGrid
            images={brandLogos}
            numberOfColumns={6}
            numberOfMobileColumns={2}
            constraint="large"
            columnGap="10px"
          />
        </VariableContent>

        <VariableContent
          backgroundColor="light"
          mainContent={
            <>
              <Typography variant="h2">
                Compare Medicare Part C plans
              </Typography>
            </>
          }
          alignMainContent="center"
          style={{
            color: '#1b0b54',
          }}
        >
          <div className="comparison-table bg-white">
            <table className="table-main">
              <thead>
                <td>Coverage</td>
                <td>HMO Plans</td>
                <td>PPO Plans</td>
                <td>SNPs</td>
              </thead>
              <tbody>
                <tr>
                  <td>Charges monthly premium</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>Covers prescription drugs</td>
                  <td>Sometimes</td>
                  <td>Sometimes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>Allows out-of-network coverage</td>
                  <td>Sometimes</td>
                  <td>Yes</td>
                  <td>Sometimes</td>
                </tr>
                <tr>
                  <td>Requires primary care physician</td>
                  <td>Sometimes</td>
                  <td>No</td>
                  <td>Sometimes</td>
                </tr>
                <tr>
                  <td>Requires referrals for specialist care</td>
                  <td>Yes</td>
                  <td>No</td>
                  <td>Sometimes</td>
                </tr>
              </tbody>
            </table>
          </div>
          <Typography variant="body" className="table-after">
            *Available on some Medicare Advantage plans
          </Typography>
        </VariableContent>

        <a
          href="https://www.trustpilot.com/review/clearlinkinsurance.com"
          target="_blank"
        >
          <VariableContent
            alignMainContent="center"
            mainContent={
              <>
                <span className="show-on-desktop">
                  <StaticImage
                    src="../../images/icons/trustpilot-reviews-desktop.svg"
                    alt="TrustPilot reviews icon"
                    layout="constrained"
                  />
                </span>
                <span className="show-on-mobile">
                  <StaticImage
                    src="../../images/icons/trustpilot-reviews-mobile.svg"
                    alt="TrustPilot reviews icon"
                    layout="constrained"
                  />
                </span>
              </>
            }
          />
        </a>

        <VariableContent
          backgroundColor="dark"
          mainContent={
            <>
              <Typography variant="h2">
                Find a Medicare Advantage plan today
              </Typography>
            </>
          }
          alignMainContent="center"
        >
          <Stack>
            <Columns>
              <Column backgroundColor="white" className="card-padding">
                <Typography variant="h5">
                  Find a Medicare Advantage plan online
                </Typography>
                <Typography variant="body">
                  Use our Medicare plan finder tool to compare plans in your
                  location—and enroll online during a qualifying enrollment
                  period.
                </Typography>
                <div className="avail-form margin-left-0">
                  <AvailabilityForm
                    placeholderText="Zip Code"
                    buttonText="Find a Medicare Advantage Plan"
                    buttonColor="primary"
                    butttonBackgroundColor="black"
                  />
                </div>
              </Column>
              <Column backgroundColor="white" className="card-padding">
                <Typography variant="h5">Give us a call</Typography>
                <LinkButton
                  variant="feature"
                  to={`tel:${rotatedNumber}`}
                  color="primary"
                  className=""
                  requestId={requestId}
                >
                  Call Now {rotatedNumber} | TTY 711
                </LinkButton>
                <Typography variant="body">
                  Our licensed agents are available Monday through Friday from
                  8:30 am to 5:00 pm MT.
                </Typography>
              </Column>
            </Columns>
          </Stack>
        </VariableContent>

        <VariableContent
          alignMainContent="left"
          mainContent={
            <Typography variant="h2">
              Frequently asked questions about Medicare Advantage
            </Typography>
          }
          className="faq-section-padding"
          style={{ color: '#1B0B54' }}
        >
          <Stack spacing="xxl" alignMainContent="center">
            <Accordion
              items={[
                {
                  title: 'How do I enroll in Medicare Advantage?',
                  content: (
                    <>
                      <Typography>
                        To enroll in a Medicare Advantage plan, you must be
                        eligible for Medicare coverage. Medicare is generally
                        available to anyone in the United States over the age of
                        65; some conditions may qualify you for Medicare
                        coverage before reaching that age:
                      </Typography>
                      <List>
                        <ListItem>Some disabilities</ListItem>
                        <ListItem>
                          End-stage renal disease (permanent kidney failure
                          requiring dialysis or transplant)
                        </ListItem>
                        <ListItem>
                          Amyotrophic lateral sclerosis (ALS, also known as Lou
                          Gehrig’s disease)
                        </ListItem>
                      </List>
                      <Typography>
                        To enroll in Medicare Advantage (Part C) coverage, use
                        our Medicare plan comparison tool to find the plan(s)
                        that work best for you and enroll online or over the
                        phone during a qualifying enrollment period:
                      </Typography>
                      <List>
                        <ListItem>
                          Annual Enrollment Period - October 15-December 7 each
                          year, available to anyone Medicare-eligible
                        </ListItem>
                        <ListItem>
                          Medicare Advantage Open Enrollment Period - January
                          1-March 31 each year, available to anyone already
                          enrolled in a Medicare Advantage plan
                        </ListItem>
                        <ListItem>
                          General Enrollment Period - January 1-March 31,
                          available for those who missed their Initial
                          Enrollment Period window to enroll in Medicare Parts A
                          & B
                        </ListItem>
                        <ListItem>
                          Special Enrollment Periods - may occur throughout the
                          year, triggered by qualifying events
                        </ListItem>
                      </List>
                    </>
                  ),
                },
                {
                  title: 'When do I enroll in Medicare Advantage?',
                  content: (
                    <>
                      <Typography>
                        You can enroll in a Medicare Advantage plan during a
                        qualified Medicare Enrollment Period. Here are a few of
                        the most common times of year to enroll in, or change,
                        your Medicare Part C coverage:
                      </Typography>
                      <List>
                        <ListItem>
                          <a href="/resources/when-is-medicare-advantage-annual-enrollment/">
                            Annual Enrollment Period
                          </a>{' '}
                          - October 15-December 7 each year, available to anyone
                          Medicare-eligible
                        </ListItem>
                        <ListItem>
                          <a href="/resources/gep-oep-enrollment-periods/">
                            Medicare Advantage Open Enrollment Period
                          </a>{' '}
                          - January 1-March 31 each year, available to anyone
                          already enrolled in a Medicare Advantage plan
                        </ListItem>
                        <ListItem>
                          <a href="/resources/special-enrollment-period-chart/ ">
                            Special Enrollment Periods
                          </a>{' '}
                          - may occur throughout the year, triggered by
                          qualifying events
                        </ListItem>
                      </List>
                    </>
                  ),
                },
                {
                  title: 'Who is eligible for Medicare Advantage?',
                  content: (
                    <>
                      <Typography>
                        Medicare Advantage plans are available to anyone
                        eligible for Original Medicare (Medicare Parts A & B,
                        coverage provided by the federal government). Medicare
                        is available to those who qualify by age or by special
                        qualifying conditions.
                      </Typography>
                      <List>
                        <ListItem>
                          <strong>Age:</strong> If you are a citizen of the
                          United States who has been living in the U.S. for the
                          previous 5 years and are 65 years of age (or
                          approaching your 65th birthday) you are eligible to
                          sign up for Medicare.
                        </ListItem>
                        <ListItem>
                          <strong>Qualifying Conditions:</strong> You may
                          qualify for early benefits if
                          <List>
                            <ListItem>
                              you are disabled and have received Social Security
                              benefits for at least 24 consecutive months;
                            </ListItem>
                            <ListItem>
                              you suffer from kidney failure, also known as
                              End-Stage Renal Disease (ESRD), and have had a
                              kidney transplant or been on dialysis for 3
                              months; or
                            </ListItem>
                            <ListItem>
                              you have amyotrophic lateral sclerosis (ALS or Lou
                              Gehrig’s disease).
                            </ListItem>
                          </List>
                        </ListItem>
                      </List>
                    </>
                  ),
                },
                {
                  title: 'How do I choose a Medicare Advantage plan?',
                  content: (
                    <>
                      <Typography>
                        Medicare Advantage plans all provide comprehensive
                        coverage of the same benefits you get from Original
                        Medicare (Parts A & B), which means any Medicare
                        Advantage plan you choose will provide hospital
                        coverage, hospice coverage, medical insurance, and
                        preventative care. Medicare Advantage plans also offer
                        additional benefits that vary by plan and may include
                        vision coverage, dental coverage, health and wellness
                        programs, and gym memberships. 
                      </Typography>
                      <Typography>
                        Use our online tool or speak to an agent to compare
                        plans available in your area. Choose the plan that
                        offers the additional benefits that most fit your needs,
                        at the right budget for you, during an appropriate
                        enrollment period.
                      </Typography>
                    </>
                  ),
                },
              ]}
            />
          </Stack>
        </VariableContent>

        <VariableContent
          backgroundColor="light"
          mainContent={
            <>
              <Typography variant="h2">Medicare resources</Typography>
            </>
          }
          alignMainContent="center"
        >
          <Stack>
            <Columns>
              <Column>
                <StaticImage
                  src="../../images/people/cute-couple.jpg"
                  alt="cute elderly couple"
                  layout="constrained"
                />
                <Typography variant="h5" style={{ marginTop: '16px' }}>
                  <a
                    href="/resources/gep-oep-enrollment-periods/"
                    style={{ color: '#1b0b54' }}
                  >
                    General Enrollment and Open Enrollment Periods
                  </a>
                </Typography>
                <Typography variant="body">
                  Learn about the two Medicare enrollment periods that run from
                  January through March.
                </Typography>
              </Column>
              <Column>
                <StaticImage
                  src="../../images/people/grandparents-on-couch-with-grandkids.jpg"
                  alt="elederly couple sit with grandkids on couch and smile"
                  layout="constrained"
                />
                <Typography variant="h5" style={{ marginTop: '16px' }}>
                  <a
                    href="/resources/when-is-medicare-advantage-annual-enrollment/"
                    style={{ color: '#1b0b54' }}
                  >
                    Medicare Annual Enrollment Period 101
                  </a>
                </Typography>
                <Typography variant="body">
                  When is the Medicare Annual Enrollment Period? Get answers to
                  this and other AEP-related questions.
                </Typography>
              </Column>
              <Column>
                <StaticImage
                  src="../../images/people/biking-on-beach.jpg"
                  alt="an elderly couple rides bicycles on the beach"
                  layout="constrained"
                />
                <Typography variant="h5" style={{ marginTop: '16px' }}>
                  <a
                    href="/resources/medicare-enrollment-101/"
                    style={{ color: '#1b0b54' }}
                  >
                    Medicare Enrollment Overview
                  </a>
                </Typography>
                <Typography variant="body">
                  Get fast facts about Medicare Enrollment, including when you
                  qualify and when to enroll.
                </Typography>
              </Column>
            </Columns>
          </Stack>
        </VariableContent>
      </>
    ),
  }

  return <Layout {...layoutProps} />
}
export default Home

export const query = graphql`
  query HomeQuery {
    allBrandyDisclaimer {
      edges {
        node {
          brandy_id
          text
          symbol
        }
      }
    }
  }
`
